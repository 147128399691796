import { styled } from "utils/emotion"
import media from "utils/mediaqueries"

export const StyledFooter = styled("div")(
  {
    position: "relative",
    zIndex: "5",
    width: "100%",
    paddingBottom: 30,
    paddingTop: 30,

    [media(0, "tablet")]: {
      // height: "150px"
      paddingBottom: 100,
    },

    ":before": {
      content: '""',
      position: "absolute",
      left: "0",
      top: "-3",
      width: "192px",
      height: "3px",

      [media(0, "mobile")]: {
        display: "none",
      },
    },
  },
  (props) => ({
    height: props.pageLayout === "layoutFullscreen" && "auto",
    marginTop: props.pageLayout === "layoutFullscreen" && "-125px",
    borderTop: props.pageLayout === "layoutScrollable" && `3px solid #353535`,
    backgroundColor:
      props.pageLayout !== "layoutFullscreen" && props.theme.backgroundPrimary,

    [media(0, "mobile")]: {
      height: props.pageLayout === "layoutFullscreen" && "150px",
      marginTop: props.pageLayout === "layoutFullscreen" && "-150px",
    },

    ":before": {
      backgroundColor: props.theme.colorTertiary,
    },
  })
)
