import React from "react";
import Grid from "components/Grid";
import GridItem from "components/GridItem";
import ListSocials from "components/ListSocials";
import NavigationFooter from "components/NavigationFooter";
import { StyledFooter } from "./styles";

export default function Footer({
  navigationFooterItems,
  navigationSocialItems,
  pageLayout
}) {
  return (
    <StyledFooter pageLayout={pageLayout}>
      <Grid center left>
        <GridItem mobile={0}>
          <ListSocials items={navigationSocialItems} />
        </GridItem>
        <GridItem mobile={0}>
          <NavigationFooter items={navigationFooterItems} />
        </GridItem>
      </Grid>
    </StyledFooter>
  );
}
