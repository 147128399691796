import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

import Link from 'components/Link';

export const ListSocialsStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  margin: '0 -6px',
  padding: '20px 0px',
  lineHeight: 0,

  [media('tablet')]: {
    margin: '0 -18px',
  },

  [media(0, 'mobile')]: {
    padding: '10px 0',
  },
});

export const ListSocialsItemStyled = styled('span')({
  display: 'inline-block',
  padding: '0 6px',
  [media('tablet')]: {
    padding: '0 18px',
  },
});

export const ListSocialsItemLinkStyled = styled(Link)({
  display: 'inline-block',
  flex: 'none',
  color: '#fff',
  opacity: '0.5',

  '&:hover': {
    opacity: 1,
  },
});
