import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { capitalizeFirstLetter } from 'utils/strings';

import Icon from 'components/Icon';

import {
  ListSocialsStyled,
  ListSocialsItemStyled,
  ListSocialsItemLinkStyled,
} from './ListSocialsStyled';

export default function ListSocials({ items }) {
  return (
    <ListSocialsStyled>
      {items.map(item => (
        <ListSocialsItemStyled
          key={item?.id}
          data={item}
          show={item?.id} // Only if navigationEntry is set within craft cms
        >
          <ListSocialsItemLinkStyled to={item?.socialLink}>
            <Icon
              type={`social${capitalizeFirstLetter(item?.socialChannel)}`} // Api not capable of capital letters in enums!
            />
          </ListSocialsItemLinkStyled>
        </ListSocialsItemStyled>
      ))}
    </ListSocialsStyled>
  );
}

ListSocials.propTypes = {
  items: PropTypes.array,
};

ListSocials.defaultProps = {
  items: [],
};

export const query = graphql`
  fragment GlobalNavigationSocialQuery on Craft_GlobalsSet {
    navigationSocial: footerSocials {
      navigationSocialItems: socialEntry {
        ... on Craft_Socials {
          id
          socialLink
          socialChannel
        }
      }
    }
  }
`;
