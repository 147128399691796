import React from "react";
import { graphql } from "gatsby";
import media from "utils/mediaqueries";

import { css, styled } from "utils/emotion";

import Link from "components/Link";

const StyledNavigationFooter = styled("div")({
  textAlign: "right",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  margin: "0 -6px",
  padding: "20px 0",

  [media("tablet")]: {
    margin: "0 -18px",
    justifyContent: "flex-end",
  },

  [media(0, "mobile")]: {
    padding: "10px 0",
  },
});

const NavigationFooterItem = styled("div")(
  {
    fontSize: "12px",
    padding: "3px 6px",

    [media("tablet")]: {
      padding: "3px 18px",
    },
  },
  (props) => ({
    fontFamily: props.theme.fontPrimary,
  })
);

const NavigationFooter = ({ items }) => {
  return (
    <>
      {items && (
        <StyledNavigationFooter>
          {items.map((item) => (
            <NavigationFooterItem key={item.id + item.__typename} data={item}>
              <Link
                className={css`
                  color: #fff;
                  opacity: 0.5;

                  &:hover {
                    color: #fff;
                    opacity: 1;
                  }
                `}
                to={item?.navigationEntry?.[0]?.fullUri}
              >
                {item.title}
              </Link>
            </NavigationFooterItem>
          ))}
        </StyledNavigationFooter>
      )}
    </>
  );
};

export default NavigationFooter;

export const query = graphql`
  fragment NavigationFooterQuery on Craft_NavigationFooter {
    id
    title
    __typename
    navigationEntry {
      id
      fullUri
    }
  }
`;
